<template>
  <div class="auth-logo">
    <img loading="lazy" src="@/assets/images/logo.webp" alt="logo" />
    <div class="auth-logo__text">Официальный магазин</div>
  </div>
</template>
<style lang="scss" scoped>
.auth-logo {
  text-align: center;
}
img {
  width: 133px;
  height: 130px;
  @media screen and (max-width: 250px) {
    width: 100%;
    height: auto;
  }
}

.auth-logo__text {
  color: #FEF1B6;
  margin-top: 8px;
}
</style>
