<script setup>

</script>

<template>
  <div>
    <h2>Реквизиты:</h2>
    <p>ИП: Малышев Алексей Владимирович</p>
    <p>ИНН: 505011726904</p>
    <p>
      Адрес: 141171, Московская обл., г. Щелково, п. Монино, ул. Новинское шоссе д.16., кв.31
    </p>
    <p>E-mail: <a href="mailto:info@ghub.games">info@ghub.games</a></p>
  </div>
</template>

<style scoped lang="scss">

</style>