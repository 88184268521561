import { createRouter, createWebHistory } from "vue-router";
import Auth from "@/views/Auth.vue";
import Policy from "@/views/Policy.vue";
import Oferta from "@/views/Oferta.vue";
import Home from "@/views/Home.vue";
import auth from "./middleware/auth";
import store from "@/store";

const routes = [
  {
    path: "/",
    name: "Login",
    component: Auth,
  },
  {
    path: "/policy",
    name: "Policy",
    component: Policy,
  },
  {
    path: "/oferta",
    name: "Oferta",
    component: Oferta,
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/home",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});
const DEFAULT_TITLE = "Gems Hub";
router.beforeEach((to, from, next) => {
  document.title = DEFAULT_TITLE;

  if (!to.meta.middleware) {
    return next();
  }

  const middleware = to.meta.middleware;

  const context = {
    to,
    from,
    next,
    store,
  };
  return middleware[0]({
    ...context,
  });
});
export default router;
