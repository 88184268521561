<template>
  <LegalContent>
    <div class="policy-content">
      <h1>Публичная оферта</h1>
      <label>Редакция № 1 от 05.12.2024 г.</label>
      <h2>Термины и определения</h2>
      <div class="termin">
        <p>Администрация</p>
        <p>ИП Малышев Алексей Владимирович</p>
      </div>
      <div class="termin">
        <p>Сайт</p>
        <p>
          сайт по адресу
          <a :href="location" target="_blank"
          >{{location}}</a
          >, предназначенный для приобретения Дополнительного функционала в Игре
        </p>
      </div>
      <div class="termin">
        <p>Игра</p>
        <p>
          игра для мобильных устройств и персональных компьютеров по названием
          “Gems Hub”, которая позволяет пользователям играть в разные виды
          мини-игр, общаться друг с другом и выигрывать игровую валюту
        </p>
      </div>
      <div class="termin">
        <p>Дополнительный функционал</p>
        <p>
          части Игры, которые можно приобрести за плату: игровая валюта токен и
          серебро, внутриигровые предметы и т.п.
        </p>
      </div>
      <div class="termin">
        <p>Пользователь</p>
        <p>дееспособное физическое лицо, которое использует Сайт</p>
      </div>
      <div class="termin">
        <p>Заказ</p>
        <p>
          запрос Пользователя на приобретение Дополнительного функционала через
          Сайт
        </p>
      </div>
      <ul>
        <li>
          <h2>1. Общие условия</h2>
          <ul>
            <li>
              1.1 Публичная оферта (далее – Оферта) определяет порядок
              использования Сайта и является публичной офертой Администрации в
              соответствии со ст. 437 ГК РФ.
            </li>
            <li>
              1.2 Соглашение адресовано исключительно Пользователям, проживающим
              на территории СНГ.
            </li>
            <li>
              1.3 Безусловным принятием (акцептом) условий Оферты считается
              оплата лицензионного вознаграждения.
            </li>
          </ul>
        </li>
        <li>
          <h2>2. Предмет</h2>
          <ul>
            <li>
              2.1 Администрация предоставляет Пользователю неисключительную
              лицензию на использование Дополнительного функционала, а
              Пользователь выплачивает лицензионное вознаграждение.
            </li>
            <li>
              2.2 Пользователь вправе использовать Дополнительный функционал:
              <ul>
                <li>
                  2.2.1 способами, предусмотренными его функциональными
                  возможностями
                </li>
                <li>2.2.2 на территории всего мира;</li>
                <li>2.2.3 в течение срока действия исключительного права.</li>
              </ul>
            </li>
            <li>
              2.3 Администрации принадлежит исключительное право на Игру и
              Дополнительный функционал.
            </li>
          </ul>
        </li>
        <li>
          <h2>3. Оформление Заказа</h2>
          <ul>
            <li>
              3.1 Пользователь оформляет Заказ в следующем порядке:
              <ul>
                <li>
                  3.1.1 Пользователь проходит авторизацию на Сайте с
                  использованием своего игрового ID (аккаунт в Игре);
                </li>
                <li>
                  3.1.2 Пользователь выбирает на Сайте Дополнительный функционал
                  и заполняет форму Заказа;
                </li>
                <li>
                  3.1.3 Пользователь оплачивает лицензионное вознаграждение.
                </li>
              </ul>
            </li>
            <li>
              3.2 Пользователь заверяет, что все указанные им при Заказе данные
              являются корректными.
            </li>
          </ul>
        </li>
        <li>
          <h2>4. Финансовые условия</h2>
          <ul>
            <li>4.1 Размер лицензионного вознаграждения указан на Сайте.</li>
            <li>
              4.2 Пользователь оплачивает лицензионное вознаграждение в порядке
              100% предоплаты путем безналичного перевода денежных средств через
              эквайринг на Сайте.
            </li>
            <li>
              4.3 Пользователь получает чек об оплате на свою на электронную
              почту.
            </li>
            <li>
              4.4 Обязанность по оплате считается исполненной с момента
              зачисления лицензионного вознаграждения на расчетный счет
              Администрации.
            </li>
            <li>
              4.5 Администрация не возвращает выплаченное лицензионное
              вознаграждение, если своевременно предоставила Пользователю право
              использования Дополнительного функционала.
            </li>
          </ul>
        </li>
        <li>
          <h2>5. Получение Дополнительного функционала</h2>
          <ul>
            <li>
              5.1 Администрация предоставляет Пользователю право использования
              дополнительного функционала в течение 3 (трех) рабочих дней с
              момента оплаты лицензионного вознаграждения.
            </li>
            <li>
              5.2 Пользователь получает доступ к Дополнительному функционалу в
              следующем порядке:
              <ul>
                <li>
                  5.2.1 Пользователь проходит авторизацию в Игре с
                  использованием своего игрового ID;
                </li>
                <li>
                  5.2.2 Пользователь получает доступ к Дополнительному
                  функционалу и вправе использовать его исключительно в рамках
                  Игры.
                </li>
              </ul>
            </li>
            <li>
              5.3 Обязательства Администрации считаются исполненными с момента
              предоставления Дополнительного функционала вне зависимости от
              того, воспользовался ли им Пользователь.
            </li>
            <li>
              5.4 Если Пользователь предоставляет третьим лицам доступ к своему
              игровому ID, то он самостоятельно несет ответственность за
              действия таких третьих лиц.
            </li>
          </ul>
        </li>
        <li>
          <h2>6. Заверения</h2>
          <ul>
            <li>
              6.1 Пользователь заверяет, что:

              <ul>
                <li>
                  6.1.1 является дееспособным физическим лицом старше 14 лет;
                </li>
                <li>
                  6.1.2 он ознакомлен с функциональными свойствами и назначением
                  Сайта;
                </li>
                <li>
                  6.1.3 он не нарушает требования законодательства РФ и Оферты
                  при использовании Сайта и Дополнительного функционала;
                </li>
                <li>
                  6.1.4 он не нарушает права и законные интересы третьих лиц.
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <h2>7. Запрещенные действия</h2>
          <ul>
            <li>
              7.1 Пользователю запрещено:

              <ul>
                <li>
                  7.1.1 использовать Сайт и Дополнительный функционал способами,
                  не предусмотренными Офертой;
                </li>
                <li>
                  7.1.2 предпринимать действия, в том числе технического
                  характера, направленные на нарушение нормального
                  функционирования Сайта;
                </li>
                <li>
                  7.1.3 использовать технические средства для сбора и обработки
                  информации Сайта;
                </li>
                <li>
                  7.1.4 предпринимать попытки обойти установленные технические
                  ограничения Сайта;
                </li>
                <li>
                  7.1.5 копировать, вносить изменения, подготавливать
                  производные материалы, декомпилировать, анализировать с
                  помощью дизассемблера или пытаться вскрыть исходный код,
                  каким-либо иным способом изменять Сайт;
                </li>
                <li>
                  7.1.6 любым способом вводить других Пользователей или
                  Администрацию в заблуждение, в том числе выдавать себя за
                  другое лицо, его представителя, без достаточных на то прав, в
                  том числе за Администрацию, а также применять другие формы и
                  способы незаконного представительства.
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <h2>8. Персональные данные</h2>
          <ul>
            <li>
              8.1 Администрация обрабатывает персональные данные Пользователя в
              соответствии с требованиями, установленными Федеральным законом "О
              персональных данных" от 27.07.2006 N 152-ФЗ.
            </li>
            <li>
              8.2 Порядок обработки и защиты персональных данных определяется
              Политикой конфиденциальности, размещенной на Сайте.
            </li>
          </ul>
        </li>
        <li>
          <h2>9. Ограничение ответственности</h2>
          <ul>
            <li>
              9.1 Дополнительный функционал предоставляется на условиях «как
              есть».
            </li>
            <li>
              9.2 Администрация вправе:

              <ul>
                <li>
                  9.2.1 изменять, дорабатывать и обновлять Дополнительный
                  функционал без согласия и уведомления Пользователя;
                </li>
                <li>
                  9.2.2 устанавливать любые ограничения на использование
                  Дополнительного функционала;
                </li>
                <li>
                  9.2.3 собирать сведения о Дополнительном функционале,
                  проводить опросы в статистических целях и для улучшения
                  качества.
                </li>
              </ul>
            </li>
            <li>
              9.3 Администрация не несет ответственность за:

              <ul>
                <li>
                  9.3.1 убытки, возникшие в связи с посещением и/или
                  использованием Дополнительного функционала или внешних
                  ресурсов, ссылки на которые могут содержаться в нем;
                </li>
                <li>
                  9.3.2 за временные сбои и перерывы в работе Дополнительного
                  функционала или Сайта и вызванные ими потерю информации.
                </li>
              </ul>
            </li>
            <li>
              9.4 Ответственность Администрации по Оферте ограничена 5000 (пятью
              тысячами) рублей.
            </li>
          </ul>
        </li>
        <li>
          <h2>10. Разрешение споров</h2>
          <ul>
            <li>
              10.1 Все споры по поводу использования Дополнительного функционала
              подлежат разрешению в соответствии с действующим законодательством
              Российской Федерации.
            </li>
            <li>
              10.2 Срок ответа на претензию – 10 (десять) рабочих дней с момента
              ее получения адресатом.
            </li>
            <li>
              10.3 Если стороны не могут разрешить спор в претензионном порядке,
              то он может быть передан на разрешение в суд по месту нахождения
              Администрации, если иное не предусмотрено законом.
            </li>
            <li>
              10.4 Если Пользователь использует логотип (товарный знак)
              Администрации для причинения вреда деловой репутации
              Администрации, то последняя вправе обратиться в суд и потребовать:

              <ul>
                <li>10.4.1 пресечения нарушения;</li>
                <li>10.4.2 взыскания убытков;</li>
                <li>10.4.3 публикации опровержения.</li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <h2>11. Заключительные положения</h2>
          <ul>
            <li>
              11.1 Договор действует с момента акцепта Оферты до исполнения
              Сторонами своих обязательств в полном объеме.
            </li>
            <li>
              11.2 В случае возникновения вопросов, связанных с Дополнительным
              функционалом, Пользователь вправе сообщить об этом Администрации
              по электронной почте
              <a href="mailto:info@ghub.games">info@ghub.games</a>.
            </li>
            <li>
              11.3 Администрация вправе изменять условия Оферты. Такие изменения
              вступают в силу по истечении 3 (трех) дней с момента размещения
              новой версии Оферты на Сайте. Продолжая использовать Сайт,
              Пользователь подтверждает свое согласие с новой версией Оферты.
            </li>
            <li>
              11.4 Администрация вправе заблокировать Пользователя в случае
              нарушения им условий Оферты и/или законодательства РФ.
            </li>
          </ul>
        </li>
      </ul>
      <LegalInfo />
    </div>
  </LegalContent>
</template>
<script>
import LegalInfo from "@/components/blocks/Legal/LegalInfo.vue";
import LegalContent from "@/components/blocks/Legal/LegalContent.vue";
export default {
  name: "OfertaPage",
  components: {
    LegalContent,
    LegalInfo,
  },

  computed: {
    location() {
      return window.location.origin
    }
  }
};
</script>
