<template>
  <div class="android">
    <a :href="link" target="_blank">Скачать для Android</a>
  </div>
</template>

<script>
export default {
  name: "ScamdoffAndroid",
  props: {
    link: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.android {
  display: flex;
  flex-direction: column;
  align-items: center;

  a:hover {
    text-decoration: underline;
  }
}
</style>
